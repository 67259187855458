import styles from "./styles.module.scss";
import { FintechHalfSheet } from "../../FintechHalfSheet";
import { FintechBanner } from "../../FintechBanner";
import Coin from "../../../assets/client/coin.svg";
import { ReactComponent as Chevron } from "../../../assets/client/chevron.svg";
import { useCallback, useState } from "react";
import clsx from "clsx";
import { WalletOffer } from "@hopper-b2b/types";
import { ButtonWrap, Divider } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { HotelOfferTermsModal } from "../../TermsAndConditionsWrapper/component";

interface HotelVoucherOfferBannerProps {
  isEligible: boolean;
  showMore: boolean;
  offer?: WalletOffer;
  minimal?: boolean;
  onClick?: () => void;
}

export const HotelVoucherOfferBanner = ({
  isEligible,
  showMore,
  offer,
  minimal = false,
  onClick,
}: HotelVoucherOfferBannerProps) => {
  const { t } = useI18nContext();
  const [openHalfSheet, setOpenHalfSheet] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);

  const handleOpenHalfSheet = useCallback(() => {
    onClick && onClick();
    setOpenHalfSheet(true);
  }, [onClick]);

  const handleCloseHalfSheet = useCallback(() => {
    setOpenHalfSheet(false);
  }, []);

  return isEligible && offer ? (
    <>
      <FintechBanner
        title={offer.offerContent.title || offer.offerContent.name}
        subtitle={!minimal ? t("hotelVoucherSubtitle") : null}
        fintechIcon={Coin}
        hideBrand
        className={clsx(
          styles.HotelVoucherOfferBanner,
          "HotelVoucherOfferBanner",
          minimal ? "minimal" : null
        )}
        onClick={showMore ? handleOpenHalfSheet : null}
      />
      {showMore && offer ? (
        <>
          <FintechHalfSheet
            className={clsx(
              styles.HotelVoucherOfferModal,
              "HotelVoucherOfferModal"
            )}
            isOpen={openHalfSheet}
            onClose={handleCloseHalfSheet}
            icon={Coin}
            title={offer.offerContent.title || offer.offerContent.name}
            description={
              <div>
                <p>{offer.offerContent.lineItemDescription}</p>
                {offer.offerContent.restrictions ||
                offer.offerContent.termsAndConditions ? (
                  <p className={styles.HotelVoucherOfferFinePrint}>
                    {t("offersCancellationDisclosure")}
                  </p>
                ) : null}
                <p className={styles.HotelVoucherOfferFinePrint}>
                  {offer.offerContent.restrictions ||
                    offer.offerContent.termsAndConditions}
                </p>
                <Divider />
                <ButtonWrap
                  className={styles.HotelVoucherTermButton}
                  onClick={() => setOpenTermsModal(true)}
                >
                  <p className={styles.HotelVoucherTerms}>
                    {t("termsAndConditions")}
                  </p>
                  <Chevron />
                </ButtonWrap>
              </div>
            }
          />
          <HotelOfferTermsModal
            openModal={openTermsModal}
            setOpenModal={setOpenTermsModal}
          />
        </>
      ) : null}
    </>
  ) : null;
};
