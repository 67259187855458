import clsx from "clsx";
import { ActionLink, Divider, MobilePopoverCard } from "@hopper-b2b/ui";
import Close from "../../assets/client/close.svg";
import CloseWhite from "../../assets/client/darkMode/close-white.svg";
import { useDarkModePreferred } from "../../utils/colors";
import styles from "./styles.module.scss";

interface FintechHalfSheetProps {
  isOpen: boolean;
  onClose: () => void;
  icon?: string;
  title: string;
  description: string | JSX.Element;
  additionalInfo?: string | JSX.Element;
  className?: string;
}

const FintechHalfSheet = ({
  isOpen,
  onClose,
  icon,
  title,
  description,
  additionalInfo,
  className,
}: FintechHalfSheetProps) => {
  const isDarkMode = useDarkModePreferred();
  return (
    <MobilePopoverCard
      open={isOpen}
      className={clsx(styles.FintechHalfSheet, className, "FintechHalfSheet")}
      contentClassName="fintech-half-sheet-modal-content"
      onClose={onClose}
      centered={true}
      topRightButton={
        <ActionLink
          onClick={onClose}
          content={
            <img
              className="close-icon"
              src={isDarkMode ? CloseWhite : Close}
              alt=""
            />
          }
        />
      }
    >
      <>
        {icon ? <img src={icon} alt="" /> : null}
        <h2 className="title">{title}</h2>
        <p className="description">{description}</p>
        {additionalInfo ? (
          <>
            <Divider />
            {additionalInfo}
          </>
        ) : null}
      </>
    </MobilePopoverCard>
  );
};

export default FintechHalfSheet;
