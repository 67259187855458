import { trackEvent } from "@hopper-b2b/api";
import {
  LodgingCheckoutTrackingEvents,
  LodgingShopTrackingEvents,
} from "@hopper-b2b/types";
import {
  ITrackingProperties,
  ITrackingPropertiesRequest,
  updateTrackingPropertiesRequest,
} from "@hopper-b2b/utilities";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getHotelAvailabilityTrackingProperties } from "../modules/availability/reducer";
import {
  getHotelShopTrackingProperties,
  getSelectedRoomRateTrackingProperties,
  getSelectedRoomTrackingProperties,
  getShopPriceChange,
  getShopTrackingProperties,
} from "../modules/shop/reducer/selectors";
import { getWalletTrackingProperties } from "../modules/wallet/reducer";
import { useCheckoutStateSelector } from "@checkout/context";
import { WalletSelectors } from "@checkout/index";

export const useTrackEvents = () => {
  const hotelAvailabilityTrackingProperties = useSelector(
    getHotelAvailabilityTrackingProperties
  );
  const hotelShopTrackingProperties = useSelector(
    getHotelShopTrackingProperties
  );
  const selectedRoomTrackingProperties = useSelector(
    getSelectedRoomTrackingProperties
  );
  const selectedRoomRateTrackingProperties = useSelector(
    getSelectedRoomRateTrackingProperties
  );
  const shopTrackingProperties = useSelector(getShopTrackingProperties);
  const shopPriceChange = useSelector(getShopPriceChange);

  const walletTrackingProperties = useSelector(getWalletTrackingProperties);

  const getTrackingProperties = useCallback(
    (
      eventType: LodgingShopTrackingEvents | LodgingCheckoutTrackingEvents
    ): ITrackingPropertiesRequest => {
      const trackingProperties = {
        properties: {},
        encryptedProperties: [],
      };

      switch (eventType) {
        case LodgingShopTrackingEvents.hotel_entry:
        case LodgingShopTrackingEvents.hotel_tapped_filter:
        case LodgingShopTrackingEvents.hotel_viewed_filters:
        case LodgingShopTrackingEvents.hotel_tapped_sort:
        case LodgingCheckoutTrackingEvents.tapped_rewards_redemption_choice:
          trackingProperties.properties = {
            ...trackingProperties.properties,
          };
          break;
        case LodgingShopTrackingEvents.hotel_loaded_list_page:
        case LodgingShopTrackingEvents.hotel_viewed_map:
        case LodgingShopTrackingEvents.hotel_viewed_lodging_in_list:
        case LodgingShopTrackingEvents.hotel_updated_list_page:
          trackingProperties.properties = {
            ...hotelAvailabilityTrackingProperties,
            ...walletTrackingProperties,
          };
          break;
        case LodgingShopTrackingEvents.hotel_viewed_cover:
        case LodgingShopTrackingEvents.hotel_loaded_room_info_list:
        case LodgingShopTrackingEvents.hotel_viewed_bed_types:
        case LodgingShopTrackingEvents.hotel_tapped_room_selection_continue:
        case LodgingCheckoutTrackingEvents.hotel_view_travelers:
        case LodgingCheckoutTrackingEvents.hotel_review_details:
        case LodgingCheckoutTrackingEvents.hotel_tapped_complete_booking:
        case LodgingCheckoutTrackingEvents.hotel_complete_buy:
          trackingProperties.properties = {
            ...hotelAvailabilityTrackingProperties,
            ...hotelShopTrackingProperties?.properties,
            ...selectedRoomTrackingProperties?.properties,
            ...selectedRoomRateTrackingProperties?.properties,
            ...shopTrackingProperties?.properties,
            ...shopPriceChange,
            ...walletTrackingProperties,
          };
          trackingProperties.encryptedProperties = [
            hotelShopTrackingProperties?.encryptedProperties,
            selectedRoomTrackingProperties?.encryptedProperties,
            selectedRoomRateTrackingProperties?.encryptedProperties,
            shopTrackingProperties?.encryptedProperties,
          ].filter(Boolean);
          break;
        case LodgingShopTrackingEvents.viewed_offer:
          trackingProperties.properties = {
            ...walletTrackingProperties,
          };
          break;
        default:
          break;
      }

      return trackingProperties;
    },
    [
      hotelAvailabilityTrackingProperties,
      hotelShopTrackingProperties?.encryptedProperties,
      hotelShopTrackingProperties?.properties,
      selectedRoomRateTrackingProperties?.encryptedProperties,
      selectedRoomRateTrackingProperties?.properties,
      selectedRoomTrackingProperties?.encryptedProperties,
      selectedRoomTrackingProperties?.properties,
      shopPriceChange,
      shopTrackingProperties?.encryptedProperties,
      shopTrackingProperties?.properties,
      walletTrackingProperties,
    ]
  );

  return useCallback(
    (
      eventType: LodgingShopTrackingEvents | LodgingCheckoutTrackingEvents,
      properties?: { [key: string]: unknown } | object,
      trackingProperties?: ITrackingProperties[]
    ) => {
      const stateTrackingProperties = getTrackingProperties(eventType);
      const updatedTrackingProperties = updateTrackingPropertiesRequest(
        stateTrackingProperties,
        trackingProperties,
        properties
      );

      trackEvent({
        eventName: eventType,
        ...updatedTrackingProperties,
      });
    },
    [getTrackingProperties]
  );
};

export const useSetLocalStorageTrackingEvents = () => {
  const hotelAvailabilityTrackingProperties = useSelector(
    getHotelAvailabilityTrackingProperties
  );
  const hotelShopTrackingProperties = useSelector(
    getHotelShopTrackingProperties
  );
  const selectedRoomTrackingProperties = useSelector(
    getSelectedRoomTrackingProperties
  );
  const selectedRoomRateTrackingProperties = useSelector(
    getSelectedRoomRateTrackingProperties
  );
  const shopTrackingProperties = useSelector(getShopTrackingProperties);

  const walletTrackingProperties = useSelector(getWalletTrackingProperties);
  const walletPaymentTrackingProperties = useCheckoutStateSelector(
    WalletSelectors.getCheckoutTrackingProperties
  );

  const trackingProperties = {
    properties: {},
    encryptedProperties: [],
  };

  return useCallback(
    (localStorageKey: string) => {
      const currTrackingProperties = {
        properties: {
          ...trackingProperties.properties,
          ...hotelAvailabilityTrackingProperties,
          ...hotelShopTrackingProperties?.properties,
          ...selectedRoomTrackingProperties?.properties,
          ...selectedRoomRateTrackingProperties?.properties,
          ...shopTrackingProperties?.properties,
          ...walletTrackingProperties,
          ...walletPaymentTrackingProperties?.properties,
        },
      };
      // don't override what's already in storage
      if (localStorage.getItem(localStorageKey) === null) {
        localStorage.setItem(
          localStorageKey,
          JSON.stringify(currTrackingProperties)
        );
      }
    },
    [
      hotelAvailabilityTrackingProperties,
      hotelShopTrackingProperties?.properties,
      selectedRoomRateTrackingProperties?.properties,
      selectedRoomTrackingProperties?.properties,
      shopTrackingProperties?.properties,
      trackingProperties.properties,
      walletPaymentTrackingProperties,
      walletTrackingProperties,
    ]
  );
};

export const removeLocalStorageTrackingEvents = (localStorageKey: string) => {
  localStorage.removeItem(localStorageKey);
};

export const getLocalStorageTrackingEvents = (localStorageKey: string) => {
  try {
    const prevTrackingEvents = JSON.parse(
      localStorage.getItem(localStorageKey)
    );
    if (typeof prevTrackingEvents === "object") {
      return prevTrackingEvents;
    }
  } catch (e) {
    // the localStorageKey is empty, return empty object
    return {};
  }
};
