import { useCallback, useContext, useEffect } from "react";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom-v5-compat";
import * as queryStringParser from "query-string";

import { B2B_PORTAL_AUTH_REDIRECT_TO } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { B2BLoadingPopup } from "@hopper-b2b/ui";

import { startSession } from "../../api/session/startSession";
import { impersonateUserSession } from "../../api/session/impersonateUserSession";
import { UserContext } from "../../App";
import {
  PATH_AUTH_INVALID_SESSION,
  PATH_AUTH_START_SESSION,
  PATH_HOME,
  ROUTE_AUTH_INVALID_SESSION,
  ROUTE_AUTH_LOGOUT,
} from "../../utils/urlPaths";
import ExpiredSession from "../ExpiredSession/component";
import {
  COLOR_MODE_PARAM,
  IMPERSONATE_COOKIE_NAME,
  IMPERSONATE_USER_PARAM,
  LOGOUT_REDIRECT,
  REDIRECT_URI_PARAM,
  SESSION_TOKEN_PARAM,
} from "./constants";
import "./styles.scss";
import { removeLocalStorageTrackingEvents } from "@hopper-b2b/lodging";
import { COLOR_MODE_COOKIE_NAME } from "../../utils/colors";

export const StartSessionComponent = () => {
  const { t } = useI18nContext();

  const navigate = useNavigate();
  const location = useLocation();
  const { updateSessionInfo } = useContext(UserContext);

  const authGenericCodeFlow = useCallback(async () => {
    const redirectToSessionStorageUrl = (url: string) => {
      navigate(url, { replace: true });
      sessionStorage.removeItem(B2B_PORTAL_AUTH_REDIRECT_TO);
    };
    const handleRedirect = (redirectUrlFromStartSession: string | null) => {
      const redirectTo = sessionStorage.getItem(B2B_PORTAL_AUTH_REDIRECT_TO);
      if (redirectUrlFromStartSession) {
        navigate(redirectUrlFromStartSession);
      } else if (redirectTo) {
        redirectToSessionStorageUrl(redirectTo);
      } else navigate(PATH_HOME, { replace: true });
    };

    try {
      const searchParams = new URLSearchParams(location?.search);
      const authToken = searchParams.get(SESSION_TOKEN_PARAM);
      const impersonateUserId = searchParams.get(IMPERSONATE_USER_PARAM);
      const redirectUri = searchParams.get(REDIRECT_URI_PARAM);
      const decodedRedirectUri = redirectUri
        ? decodeURIComponent(redirectUri)
        : null;

      const colorMode = searchParams.get(COLOR_MODE_PARAM);
      if (colorMode) {
        document.cookie = `${COLOR_MODE_COOKIE_NAME}=${colorMode};path=/`;
      }

      if (impersonateUserId) {
        const sessionInfo = await impersonateUserSession(impersonateUserId);
        // Set impersonation cookie to persist for the session.
        document.cookie = `${IMPERSONATE_COOKIE_NAME}=true;path=/`;
        updateSessionInfo(sessionInfo);
        handleRedirect(decodedRedirectUri);
      } else if (authToken) {
        const sessionInfo = await startSession(authToken);
        updateSessionInfo(sessionInfo);
        handleRedirect(decodedRedirectUri);
      } else {
        navigate(PATH_AUTH_INVALID_SESSION, { replace: true });
      }
    } catch (err) {
      navigate(PATH_AUTH_INVALID_SESSION, { replace: true });
    }
  }, [navigate, location?.search, updateSessionInfo]);

  useEffect(() => {
    const queryString = queryStringParser.parse(location.search);

    if (queryString) authGenericCodeFlow();
    else navigate(PATH_AUTH_INVALID_SESSION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <B2BLoadingPopup
      open
      message={t("loading")}
      popupSize={"mobile"}
      fullScreen
    />
  );
};

export const InvalidSessionComponent = () => {
  useEffect(() => {
    // key is from loding-checkout initalContext
    removeLocalStorageTrackingEvents("nubank-tracking-properties");
  }, []);
  return <ExpiredSession />;
};

export const LogoutComponent = () => {
  const navigate = useNavigate();
  const { t } = useI18nContext();
  const logout = async () => {
    try {
      // TODO: add logout api call
      //   const logoutResponse = await postLogout();
      //   if (logoutResponse.destinationUrl)
      //   window.location.replace(logoutResponse.destinationUrl);
      // history.push(LOGOUT_REDIRECT);
      navigate(LOGOUT_REDIRECT);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <B2BLoadingPopup
      open
      message={t("loggingOut")}
      popupSize={"mobile"}
      fullScreen
    />
  );
};

export const AuthModule = () => {
  return (
    <Routes>
      <Route path={ROUTE_AUTH_LOGOUT} element={<LogoutComponent />} />
      <Route
        path={PATH_AUTH_START_SESSION}
        element={<StartSessionComponent />}
      />
      <Route
        path={ROUTE_AUTH_INVALID_SESSION}
        element={<InvalidSessionComponent />}
      />
    </Routes>
  );
};
