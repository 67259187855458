import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { ExchangeActionEnum } from "@b2bportal/air-exchange-api";
import { trackEvent } from "@hopper-b2b/api";
import { PATH_HOME } from "@hopper-b2b/common-utils";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  CfarTrackingEvents,
  CfarTrackingPageSelected,
  ChfarTrackingEvents,
  ChfarTrackingPageSelected,
  ClientName,
  DisruptionTrackingEvents,
  FintechProductType,
  ITripTerminus,
} from "@hopper-b2b/types";
import {
  ActionLink,
  BackButton,
  EditTripButtons,
  IconComponent,
  IconName,
  LearnMore,
  Slot,
} from "@hopper-b2b/ui";
import {
  CustomHeaderButton,
  getEnvVariables,
  hasHTSConnectSupport,
  useDeviceTypes,
  useEnableCfar,
  useEnableChfar,
  useEnableMissedConnection,
  useEnableScheduleChange,
  useUberBridge,
} from "@hopper-b2b/utilities";

import { ClientContext } from "../../../../App";
import { getOutboundSelection } from "../../../exchange/reducer/selectors";
import {
  getTripCategory,
  hasSelectedDates,
  MobileFlightSearchStep,
} from "../../../search/reducer";
import { useUpdateFlightShopStep } from "../../hooks/flightShopNavigationHooks";
import { useFetchFlights } from "../../hooks/useFetchFlights";
import {
  flightShopProgressSelector,
  FlightShopStep,
  getCalendarBuckets,
  getPriceRangeLegends,
  isInPredictionStepSelector,
} from "../../reducer";
import { CalendarModal } from "../CalendarModal";
import { LocationModal } from "../LocationModal";
import { FlightShopProgressHeaderConnectorProps } from "./container";
import "./styles.scss";

export interface IFlightShopProgressHeaderProps
  extends FlightShopProgressHeaderConnectorProps {
  assets?: Record<string, any>;
  backIcon?: IconDefinition;
  className?: string;
  inExchange?: boolean;
  isFilterHidden?: boolean;
  isTripEditHidden?: boolean;
  onFiltersClick: () => void;
  onShopParamsChange?: () => void;
}

const OPEN_FILTERS_ACTION = "open_filters_action";

export const FlightShopProgressHeader = ({
  inExchange,
  isOneWay,
  origin,
  destination,
  departureDate,
  returnDate,
  openCalendarModal,
  setOpenCalendarModal,
  openLocationModal,
  setOpenLocationModal,
  onFiltersClick,
  onShopParamsChange,
  className,
  isFilterHidden,
  assets,
  isTripEditHidden = false,
  setOrigin,
  setDestination,
  setDepartureDate,
  setReturnDate,
  passengerCount,
  setMobileSearchProgress,
}: IFlightShopProgressHeaderProps) => {
  const clientContext = useContext(ClientContext);

  const { matchesMobile } = useDeviceTypes();

  const { setHeader } = useUberBridge();
  const history = useHistory();
  const { t } = useI18nContext();

  const updateFlightShopStep = useUpdateFlightShopStep();

  const [tempOrigin, setTempOrigin] = useState<ITripTerminus | null>(origin);
  const [tempDestination, setTempDestination] = useState<ITripTerminus | null>(
    destination
  );

  const [tempDepartureDate, setTempDepartureDate] = useState<Date | null>(
    departureDate
  );
  const [tempReturnDate, setTempReturnDate] = useState<Date | null>(returnDate);

  const currentProgress = useSelector(flightShopProgressSelector);
  const isInPredictionStep = useSelector(isInPredictionStepSelector);
  const outboundSelection = useSelector(getOutboundSelection);
  const tripCategory = useSelector(getTripCategory);
  const hasDates = useSelector(hasSelectedDates);
  const priceMap = useSelector(getPriceRangeLegends);
  const months = useSelector(getCalendarBuckets);

  // CFAR
  const showFlightCFAR = useEnableCfar();
  const isInCfarOffersStep = useMemo(
    () => showFlightCFAR && currentProgress === FlightShopStep.CfarOffers,
    [currentProgress, showFlightCFAR]
  );

  // CHFAR
  const showFlightChfar = useEnableChfar();
  const isInChfarOfferStep = useMemo(
    () => showFlightChfar && currentProgress === FlightShopStep.ChfarOffer,
    [currentProgress, showFlightChfar]
  );

  // Disruption
  const showFlightScheduleChange = useEnableScheduleChange();
  const isInScheduleChangeStep = useMemo(
    () =>
      showFlightScheduleChange &&
      currentProgress === FlightShopStep.ScheduleChange,
    [currentProgress, showFlightScheduleChange]
  );
  const showFlightMissedConnection = useEnableMissedConnection();
  const isInMissedConnectionStep = useMemo(
    () =>
      showFlightMissedConnection &&
      currentProgress === FlightShopStep.MissedConnection,
    [currentProgress, showFlightMissedConnection]
  );

  const onOpenCfarLearnMore = useCallback(() => {
    trackEvent({
      eventName: CfarTrackingEvents.TAP_LEARN_MORE,
      properties: { page_selected: CfarTrackingPageSelected.OFFER },
    });
  }, []);

  const onOpenChfarLearnMore = useCallback(() => {
    trackEvent({
      eventName: ChfarTrackingEvents.TAP_LEARN_MORE,
      properties: { page_selected: ChfarTrackingPageSelected.OFFER },
    });
  }, []);

  const onOpenDisruptionLearnMore = useCallback(() => {
    trackEvent({
      eventName: DisruptionTrackingEvents.TAP_DELAYS_PROTECTION_LEARN_MORE,
      properties: undefined,
    });
  }, []);

  useEffect(() => {
    if (getEnvVariables("clientName") !== ClientName.UBER) return;

    let title = "";
    let customButtons: Array<CustomHeaderButton> = [];

    switch (currentProgress) {
      case FlightShopStep.ChooseDeparture: {
        title = t("flightsPageTitles.selectOutbound");
        customButtons = [
          {
            icon: "SLIDERS",
            action: {
              name: OPEN_FILTERS_ACTION,
              callback: onFiltersClick,
            },
          },
        ];
        break;
      }
      case FlightShopStep.ChooseReturn: {
        title = t("flightsPageTitles.selectReturn");
        customButtons = [
          {
            icon: "SLIDERS",
            action: {
              name: OPEN_FILTERS_ACTION,
              callback: onFiltersClick,
            },
          },
        ];
        break;
      }
      case FlightShopStep.ReviewItinerary: {
        title = t("flightsPageTitles.reviewItinerary");
        break;
      }
      case FlightShopStep.FareDetails: {
        title = t("flightsPageTitles.fareDetails");
        break;
      }
      case FlightShopStep.ScheduleChange:
      case FlightShopStep.MissedConnection: {
        title = t("flightsPageTitles.disruptionGuarantee");
        break;
      }
    }

    setHeader({
      title: title,
      customButtons: customButtons,
    });
  }, [currentProgress, onFiltersClick, setHeader, t]);

  const handleExchangeGoBack = useCallback(() => {
    if (
      currentProgress === FlightShopStep.ChooseReturn &&
      outboundSelection === ExchangeActionEnum.Change
    ) {
      updateFlightShopStep(FlightShopStep.ChooseDeparture);
    } else {
      updateFlightShopStep(FlightShopStep.ExchangeLocationsPicker);
    }
  }, [currentProgress, outboundSelection, updateFlightShopStep]);

  const handleGoBack = useCallback(() => {
    if (inExchange) {
      return handleExchangeGoBack();
    }

    return history.goBack();
  }, [handleExchangeGoBack, history, inExchange]);

  const fetchFlights = useFetchFlights();

  const handleSearch = useCallback(() => {
    setOrigin(tempOrigin);
    setDestination(tempDestination);
    setDepartureDate(tempDepartureDate);
    setReturnDate(tempReturnDate);
    setOpenCalendarModal(false);

    if (onShopParamsChange) {
      onShopParamsChange();
    } else {
      updateFlightShopStep(FlightShopStep.ChooseDeparture);
      fetchFlights();
    }
  }, [
    fetchFlights,
    setOrigin,
    tempOrigin,
    setDestination,
    tempDestination,
    setDepartureDate,
    tempDepartureDate,
    setReturnDate,
    tempReturnDate,
    updateFlightShopStep,
    setOpenCalendarModal,
    onShopParamsChange,
  ]);

  const resetModalUpdates = useCallback(() => {
    if (!openLocationModal) {
      setTempDepartureDate(departureDate);
      setTempReturnDate(returnDate);
    }
    if (!openCalendarModal) {
      setTempOrigin(origin);
      setTempDestination(destination);
    }
  }, [
    departureDate,
    destination,
    openCalendarModal,
    openLocationModal,
    origin,
    returnDate,
  ]);

  useEffect(() => {
    if (!openCalendarModal && !openLocationModal) {
      resetModalUpdates();
    }
  }, [openCalendarModal, openLocationModal, resetModalUpdates]);

  const isNubank = getEnvVariables("clientName") === ClientName.NUBANK;
  const isHopper = getEnvVariables("clientName") === ClientName.HOPPER;
  const isHTSConnect = hasHTSConnectSupport();
  const locationLabel = useMemo(
    () =>
      isNubank || isHTSConnect
        ? `${origin?.id.code.code} - ${destination?.id.code.code}`
        : `${origin?.label.split(",")[0]} (${origin?.id.code.code}) - ${
            destination?.label.split(",")[0]
          } (${destination?.id.code.code})`,
    [origin, destination, isNubank, isHTSConnect]
  );

  const title = useMemo(() => {
    switch (currentProgress) {
      case FlightShopStep.ChooseDeparture:
        return t("flightsPageTitles.selectOutbound");
      case FlightShopStep.ChooseReturn:
        return t("flightsPageTitles.selectReturn");
      case FlightShopStep.ReviewItinerary:
        return t("flightShopReview.itineraryHeader");
      case FlightShopStep.FintechSelection:
        return t("fintechSelection.header");
      case FlightShopStep.ScheduleChange:
      case FlightShopStep.MissedConnection: {
        return t("flightsPageTitles.disruptionGuarantee");
      }
      case FlightShopStep.CfarOffers:
        return t("cfar.header");
      case FlightShopStep.ChfarOffer:
        return t("fintechSelection.chfarTitle");
      case FlightShopStep.FareDetails:
        return t("flightsPageTitles.fareDetails");
      case FlightShopStep.WalletOffers:
        return "WALLET";
    }
  }, [currentProgress]);

  const redirectHome = useCallback(() => history.push(PATH_HOME), [history]);

  const LocationSearchButton = useMemo(
    () =>
      isHTSConnect ? (
        <Slot
          id="mobile-locations-button"
          handleEditLocation={() => {
            if (matchesMobile) {
              setOpenLocationModal(true);
            } else {
              redirectHome();
            }
          }}
          locationLabel={locationLabel}
          passengerCount={passengerCount}
        />
      ) : null,
    [locationLabel, passengerCount]
  );

  // Temporary redirect to search onClick of shop location pill until new autocomplete component is built since shop modal is creating major scroll issues on Nubank iOS
  const redirectToSearch = useCallback(() => {
    history.replace("/flights/search");
    setMobileSearchProgress(MobileFlightSearchStep.LocationSearch);
  }, [history]);

  return (
    <>
      <div className={clsx("flight-shop-progress-header-root-container")}>
        <Box className={clsx("flight-shop-progress-header-root", className)}>
          <Box className="flight-shop-progress-header-container">
            <Box className="go-back-button-section">
              <BackButton onClick={handleGoBack} />
            </Box>
            {!isTripEditHidden ? (
              <EditTripButtons
                isOneWay={isOneWay}
                origin={origin}
                destination={destination}
                departureDate={departureDate}
                returnDate={returnDate}
                openCalendarModal={openCalendarModal}
                setOpenCalendarModal={setOpenCalendarModal}
                openLocationModal={openLocationModal}
                setOpenLocationModal={setOpenLocationModal}
                assets={assets}
                setTempReturnDate={setTempReturnDate}
                setTempDepartureDate={setTempDepartureDate}
                setTempOrigin={setTempOrigin}
                setTempDestination={setTempDestination}
                passengerCount={passengerCount}
                onFiltersClick={onFiltersClick}
                redirectHome={redirectHome}
                filterImg={clientContext?.assets?.filter}
                isInPredicitonStep={isInPredictionStep}
                customLocationSearchButton={LocationSearchButton}
                locationLabel={locationLabel}
              />
            ) : (
              title && (
                <Typography
                  className="flight-shop-progress-header-title"
                  variant="h6"
                >
                  {title}
                </Typography>
              )
            )}
            <Box
              className={clsx("filter-button-section", {
                hidden: isFilterHidden,
              })}
            >
              {isInPredictionStep && !isHopper && (
                <ActionLink
                  className={clsx("prediction-filters-button")}
                  onClick={() => onFiltersClick()}
                  content={
                    <Typography variant="body2">
                      {t("searchFilters")}
                    </Typography>
                  }
                />
              )}
              {isInCfarOffersStep && !isHopper && (
                <LearnMore
                  label={t("learnMore")}
                  description={
                    <I18nMarkup tKey={"cfarOffers.learnMoreDescription"} />
                  }
                  productType={FintechProductType.CfarPurchase}
                  descriptionAdditional={t("cfarOffers.learnMoreDescription2")}
                  header={t("cfarOffers.learnMoreHeader")}
                  title={t("cfarOffers.learnMoreTitle")}
                  className="cfar-learn-more"
                  onOpen={onOpenCfarLearnMore}
                />
              )}
              {isInChfarOfferStep && !isHopper && (
                <LearnMore
                  label={t("learnMore")}
                  description={
                    <I18nMarkup tKey={"chOffer.learnMoreDescription"} />
                  }
                  productType={FintechProductType.Chfar}
                  descriptionAdditional={t("chOffer.learnMoreDescription2")}
                  header={t("chOffer.learnMoreHeader")}
                  title={t("chOffer.learnMoreTitle")}
                  className="cfar-learn-more"
                  onOpen={onOpenChfarLearnMore}
                />
              )}
              {isInScheduleChangeStep && !isHopper && (
                <LearnMore
                  label={t("learnMore")}
                  description={
                    <I18nMarkup tKey={"disruptionInfoModal.description"} />
                  }
                  productType={FintechProductType.Disruption}
                  header={t("disruptionInfoModal.header")}
                  title={t("disruptionInfoModal.title")}
                  descriptionAdditional={t(
                    "disruptionInfoModal.descriptionAdditional"
                  )}
                  className="disruption-learn-more"
                  onOpen={onOpenDisruptionLearnMore}
                />
              )}
              {isInMissedConnectionStep && !isHopper && (
                <LearnMore
                  label={t("learnMore")}
                  description={
                    <I18nMarkup
                      tKey={"missedConnectionInfoPopup.moreInformation"}
                    />
                  }
                  productType={FintechProductType.MissedConnection}
                  header={t("missedConnectionInfoPopup.header")}
                  title={t("disruptionInfoModal.title", {
                    connections: 0 /* ?? */,
                  })}
                  descriptionAdditional={t("descriptionAdditional")}
                  className="disruption-learn-more"
                />
              )}
              {!isFilterHidden && (
                <ActionLink
                  className={clsx("prediction-filters-button")}
                  onClick={() => onFiltersClick()}
                  content={
                    clientContext?.assets?.filter ? (
                      <img
                        src={clientContext.assets.filter}
                        alt={t("filterAndSortMenu")}
                      />
                    ) : (
                      <IconComponent
                        ariaLabel={t("filterAndSortMenu")}
                        className={clsx("filter-icon")}
                        name={IconName.FilterMenu}
                      />
                    )
                  }
                />
              )}
            </Box>
          </Box>
        </Box>
        {/* Edit trip section below flight shop header - tenant-specific, hidden by default */}
        <div
          className={clsx("flight-shop-edit-section", {
            hidden: !(
              currentProgress === FlightShopStep.ChooseDeparture ||
              currentProgress === FlightShopStep.ChooseReturn
            ),
          })}
        >
          <EditTripButtons
            isOneWay={isOneWay}
            origin={origin}
            destination={destination}
            departureDate={departureDate}
            returnDate={returnDate}
            openCalendarModal={openCalendarModal}
            setOpenCalendarModal={setOpenCalendarModal}
            openLocationModal={openLocationModal}
            setOpenLocationModal={setOpenLocationModal}
            assets={assets}
            setTempReturnDate={setTempReturnDate}
            setTempDepartureDate={setTempDepartureDate}
            setTempOrigin={setTempOrigin}
            setTempDestination={setTempDestination}
            passengerCount={passengerCount}
            onFiltersClick={onFiltersClick}
            redirectHome={redirectHome}
            filterImg={clientContext?.assets?.filter}
            isInPredicitonStep={isInPredictionStep}
            customLocationSearchButton={LocationSearchButton}
            locationLabel={locationLabel}
            customEditLocation={isNubank ? redirectToSearch : null}
          />
        </div>
      </div>
      {openLocationModal && (
        <LocationModal
          open={openLocationModal}
          onClose={() => {
            setOpenLocationModal(false);
          }}
          origin={tempOrigin}
          destination={tempDestination}
          setOrigin={setTempOrigin}
          setDestination={setTempDestination}
          setReturnDate={setTempReturnDate}
        />
      )}
      {openCalendarModal && (
        <Slot
          id="calendar-picker"
          onComplete={handleSearch}
          tripCategory={tripCategory}
          open={openCalendarModal}
          departureDate={tempDepartureDate}
          returnDate={tempReturnDate}
          setDepartureDate={setTempDepartureDate}
          setReturnDate={setTempReturnDate}
          closePopup={() => {
            setOpenCalendarModal(false);
          }}
          disabled={hasDates}
          trackingProperties={{
            origin: origin?.label || "",
            destination: destination?.label || "",
            tripCategory: tripCategory,
          }}
          isMobile={matchesMobile}
          months={months}
          headerTitle="Select Dates"
          startDateLabel="Departure"
          endDateLabel="Return"
          prices={priceMap}
          origin={tempOrigin?.id?.code || origin?.id.code}
          destination={tempDestination?.id?.code || destination?.id.code}
          component={
            <CalendarModal
              open={openCalendarModal}
              onClose={() => {
                setOpenCalendarModal(false);
              }}
              onGoBack={() => {
                setOpenCalendarModal(false);
                setOpenLocationModal(true);
              }}
              returnDate={tempReturnDate}
              departureDate={tempDepartureDate}
              setDepartureDate={setTempDepartureDate}
              setReturnDate={setTempReturnDate}
              handleSearch={handleSearch}
              originCode={tempOrigin?.id?.code || origin?.id.code}
              destinationCode={
                tempDestination?.id?.code || destination?.id.code
              }
            />
          }
        />
      )}
    </>
  );
};
