import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import { ReactComponent as TripAdvisorIcon } from "../../assets/icons/tripadvisor/tripadvisor-icon.svg";
import { Slot } from "../Slots";
import styles from "./TripAdvisorBadge.module.scss";

export type TripAdvisorBadgeProps = {
  scoreOutOfFive: number;
  withTripAdvisorIcon?: boolean;
  translateToTheLeft?: boolean;
};

export const TripAdvisorBadge = (props: TripAdvisorBadgeProps) => (
  <Slot
    id="tripadvisor-badge"
    component={<TripAdvisorBadgeInternal {...props} />}
    {...props}
  />
);

const TripAdvisorBadgeInternal = ({
  scoreOutOfFive,
  withTripAdvisorIcon = true,
  translateToTheLeft = false,
}: TripAdvisorBadgeProps) => {
  const { t } = useI18nContext();
  const scoreOneDecimalPlace = scoreOutOfFive.toFixed(1);

  const tripAdvisorBadgeUrl = `https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/s${scoreOneDecimalPlace}-MCID-5.svg`;

  return (
    <div
      className={clsx(
        styles.TripAdvisorBadge,
        // The SVG contains inner spacing that cannot be removed
        translateToTheLeft
          ? withTripAdvisorIcon
            ? styles.translateToTheLeftWithIcon
            : styles.translateToTheLeft
          : null
      )}
    >
      {withTripAdvisorIcon ? (
        <TripAdvisorIcon
          className={clsx(styles.TripAdvisorIcon, "trip-advisor-icon")}
        />
      ) : null}
      <img
        src={tripAdvisorBadgeUrl}
        alt={t("tripAdvisorBadge", { scoreOneDecimalPlace })}
      />
    </div>
  );
};
