import { axiosInstance } from "@hopper-b2b/api";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWalletBestOffers, getWalletOffers } from "../reducer";
import {
  fetchWalletOffers,
  setSelectedWalletOffer,
  setWalletOffers,
  setWalletOffersFailed,
} from "../actions/actions";
import {
  GetWalletOffersResponseEnum,
  WalletVouchersResponse,
} from "@hopper-b2b/types";
import { useEnableWallet } from "@hopper-b2b/utilities";
import { useExperiment } from "@hopper-b2b/experiments";

export const useWalletOffers = () => {
  const dispatch = useDispatch();
  const path = "/api/v0/wallet/vouchers";
  const isWalletEnabled = useEnableWallet();
  const offers = useSelector(getWalletOffers);
  const bestOffer = useSelector(getWalletBestOffers);
  const [offersLoaded, setOffersLoaded] = useState(offers?.length > 0);
  const enableVouchers10 = useExperiment(
    "hotel-voucher-discount-2024-10-percent"
  );
  const enableVouchers20 = useExperiment(
    "hotel-voucher-discount-2024-20-percent"
  );

  const getOffers = useCallback(async () => {
    try {
      dispatch(fetchWalletOffers());

      const partnerExperiments = enableVouchers10
        ? "hotel-voucher-discount-2024-10-percent"
        : enableVouchers20
        ? "hotel-voucher-discount-2024-20-percent"
        : "";

      const req = {
        partnerExperiments,
      };

      const getOffersResponse: WalletVouchersResponse = (
        await axiosInstance.put(path, req)
      ).data;
      if (
        getOffersResponse.GetVouchersResponse ===
        GetWalletOffersResponseEnum.Success
      ) {
        // For now, setting the first one in the array as the best
        dispatch(
          setWalletOffers({
            offers: getOffersResponse.offers,
            bestOffer: getOffersResponse.offers[0],
          })
        );
        // For now, setting the first one as the selected offer
        dispatch(setSelectedWalletOffer(getOffersResponse.offers[0]));
      } else {
        dispatch(setWalletOffersFailed());
      }
    } catch (e) {
      console.warn("wallet fetching error:", e);
      dispatch(setWalletOffersFailed());
    } finally {
      setOffersLoaded(true);
    }
  }, [dispatch, enableVouchers10, enableVouchers20]);

  useEffect(() => {
    if (isWalletEnabled) {
      getOffers();
    }
  }, [getOffers, isWalletEnabled]);

  return {
    loading: !offersLoaded,
    offers: offers,
    bestOffer: bestOffer,
  };
};
