import {
  asB2BMuiTheme,
  defaultThemeConfigWithBasicColors,
} from "@hopper-b2b/themes";

export const nubankColors = {
  ...defaultThemeConfigWithBasicColors,
  "black-alpha-40": "#00000052",
  "black-alpha-60": "#000000A3",
  "black-alpha-100": "#000000F5",
  "black-20": "rgba(0, 0, 0, 0.96)",
  "black-30": "rgba(0, 0, 0, 0.64)",
  "black-40": "rgba(0, 0, 0, 0.72)",
  black: "#000000",
  "blue-10": "#E2F2FB",
  "blue-20": "#C5E5F6",
  "blue-30": "#73C1E9",
  "blue-50": "#176CB8",
  "blue-60": "#0058A7",
  "brown-20": "#E6E0DE",
  "brown-30": "#814B00",
  "brown-50": "#816359",
  "citrus-10": "#EBF5BA",
  "citrus-20": "#BCEBCC",
  "citrus-30": "#A0C61B",
  "citrus-50": "#567400",
  "citrus-60": "#486000",
  "cobalt-10": "#EBEFFC",
  "cobalt-20": "#D9E1F9",
  "cobalt-60": "#284EC1",
  "cobalt-50": "#3E62D0",
  "cobalt-70": "#1035A3",
  "gray-10": "#EFEFEF",
  "gray-20": "#E1E1E1",
  "gray-30": "#B8B8B8",
  "gray-40": "rgba(0, 0, 0, 0.32)",
  "gray-80": "#505050",
  "gray-50": "#6A6A6A",
  "gray-100": "#111111",
  "gray-200": "#222222",
  "gray-300": "#5C5C5C",
  "green-10": "#DDF5E5",
  "green-20": "#BCEBCC",
  "green-30": "#57CE7F",
  "green-50": "#0C7A3A",
  "indigo-10": "#F3ECFC",
  "indigo-90": "#2A1050",
  "lavender-10": "#EEEEFF",
  "lavender-20": "#DEDEFF",
  "lavender-50": "#615BCC",
  "lavender-60": "#4F46BE",
  "orange-10": "#FFECD6",
  "orange-20": "#FFDAB1",
  "orange-30": "#FFDAB1",
  "orange-50": "#AF4D0E",
  "pink-10": "#FBEBF4",
  "pink-20": "#F7D7EA",
  "pink-40": "#E063B1",
  "pink-50": "#C41397",
  "pink-60": "#A4107E",
  "purple-10": "#F6ECFF",
  "purple-20": "#ECD9FF",
  "purple-30": "#D2A5FF",
  "purple-50": "#9436E1",
  "purple-60": "#820AD1",
  "purple-70": "#610F9B",
  "purple-80": "#3E1874",
  "red-10": "#FFEAE8",
  "red-20": "#FFD8D5",
  "red-30": "#FF9D98",
  "red-40": "#FFD8D5",
  "red-50": "#D01D1C",
  "teal-10": "#DBF4F4",
  "teal-20": "#B7EAEA",
  "teal-30": "#4FC9CA",
  "teal-50": "#0D7489",
  "teal-60": "#0B6072",
  "violet-10": "#F3ECFC",
  "violet-20": "#E7DBFF",
  "violet-30": "#C7AAFF",
  "violet-50": "#7B4DD6",
  "violet-70": "#53209C",
  "violet-80": "#391979",
  "violet-90": "#271152",
  white: "#ffffff",
  "white-alpha-50": "#FFFFFF7A",
  "white-alpha-80": "#FFFFFFCC",
  "white-alpha-90": "#FFFFFFE0",
  "yellow-20": "#FFEFC1",
  "yellow-30": "#EBAE2E",
};
export const nubankVariables = {
  ...nubankColors,
  primary: "var(--purple-60)",
  "primary-text": "var(--black)",
  "secondary-text": "var(--black-alpha-60)",
  "base-body-background": "var(--white)",
  "secondary-button-background": "var(--grey)",
  "primary-disabled": "var(--gray-10)",
  "primary-hover": "var(--black-hover)",
  "button-background": "var(--primary)",
  "button-background-disabled": "var(--primary-disabled)",
  "button-background-hover": "var(--primary-hover)",
  "button-border": "2px solid var(--primary)",
  "button-border-radius": "64px",
  "halfsheet-border-radius": "1rem",
  "mobile-banner": "var(--primary)",
  "loading-popup": "var(--white)",
  "loading-screen-bar-background": "var(--grey-10)",
  "link-text": "var(--primary)",
  "action-link-active-text": "var(--primary)",
  "hamburger-menu-color": "var(--white)",
  "filter-background-selected": "var(--labender-10)",
  "card-tag-text": "var(--green-50)",
  "card-tag-background": "var(--green-10)",
  "divider-color": "var(--border-default)",
  // calendar colors
  "bucket-0-color": "var(--citrus-20)",
  "bucket-1-color": "var(--yellow-20)",
  "bucket-2-color": "var(--orange-30)",
  "bucket-3-color": "var(--red-40)",
  "bucket-0-color-text": "var(--green-50)",
  "bucket-1-color-text": "var(--brown-30)",
  "bucket-2-color-text": "var(--orange-50)",
  "bucket-3-color-text": "var(--red-50)",
  // a11y calendar colors
  "bucket-0-color-a11y": "var(--violet-20)",
  "bucket-1-color-a11y": "var(--violet-30)",
  "bucket-2-color-a11y": "var(--violet-50)",
  "bucket-3-color-a11y": "var(--violet-80)",
  "bucket-0-color-a11y-text": "var(--violet-90)",
  "bucket-1-color-a11y-text": "var(--violet-90)",
  "bucket-2-color-a11y-text": "var(--white)",
  "bucket-3-color-a11y-text": "var(--white)",

  "info-details-popup-border": "var(--grey-10)",
  "inverse-text": "var(--white)",
  "input-label-text": "var(--primary-text)",
  "input-background": "transparent",
  "red-error-color": "var(--red-50)",
  "card-border": "2px solid var(--gray-10)",
  "card-border-thin": "1px solid var(--gray-10)",
  "price-freeze-card-color": "var(--blue-10)",
  "price-freeze-card-button": "var(--blue-20)",
  "price-freeze-tag": "var(--blue)",
  "success-color": "var(--citrus-20)",
  "content-feedback-success": "var(--green-50)",
  "content-feedback-neutral": "var(--black-alpha-neutral)",
  "pending-color": "var(--yellow-20)",
  "price-freeze-purchase-banner-background-color": "var(--blue-30)",
  "price-freeze-purchase-banner-text-color": "var(--primary)",
  "price-prediction-gradient-wait": "var(--red-40)",
  "price-prediction-gradient-fair": "var(--orange-30)",
  "price-prediction-gradient-good": "var(--yellow-20)",
  "price-prediction-gradient-great": "var(--citrus-20)",
  "spinner-border-color": "var(--grey-10)",
  "spinner-top-border-color": "var(--primary)",
  "price-freeze-text": "var(--blue-400)",
  "date-hover-color": "transparent",
  "warning-error-color-transparent": "var(--yellow-10)",
  "disruption-background-color": "var(--citrus-20)",
  "fare-details-banner": "var(--primary)",
  "fare-details-banner-text": "var(--primary-text)",
  "search-autocomplete-subheader-background": "var(--white)",
  "border-default": "var(--gray-10)",
  "content-default": "var(--black-20)",
  "content-on-color": "var(--white)",
  "content-subtle": "var(--black-30)",
  "content-disabled": "var(--gray-40)",
  "surface-subtle": "var(--gray-10)",
  "surface-disabled": "var(--gray-10)",
  "surface-accent-primary": "var(--purple-60)",
  "content-accent-primary": "var(--purple-60)",
  "surface-accent-primary-subtle": "var(--purple-10)",
  "surface-decorative-01-subtle": "var(--lavender-10)",
  "surface-feedback-attention": "var(--orange-10)",
  "floating-button-background": "var(--white)",
  "selected-fare-background": "var(--purple-10)",
  "selected-fare-text": "var(--primary)",
  "system-gray-80": "var(--gray-80)",
  "border-accent-selected": "2px solid var(--primary)",
  "surface-overlay-strong": "var(--black-40)",
  "expand-chevron": "var(--gray-300)",
  "large-button-font-size": "1rem",
  "large-button-font-weight": "500",
  "large-button-line-height": "130%",
  "large-button-padding": "1.125rem 1rem",
  "primary-button-background": "var(--primary)",
  "primary-button-font-color": "var(--white)",
  "secondary-button-font-color": "var(--content-default)",
  "trips-confirmed-title-background": "var(--green-50)",
  "filter-slider-color": "var(--purple-80)",

  // calendar
  "date-selected-color": "var(--purple-10)",
  "date-selected-text-color": "var(--primary-text)",
  "date-text-color": "var(--primary-text)",
  "date-disabled-color": "none",
  "calendar-weekday-labels-background": "var(--base-body-background)",
  "calendar-weekday-labels-text": "var(--primary-text)",
  // header
  "header-primary": "var(--primary-text)",
  "header-travel-destination": "var(--white)",
  "header-travel-passenger": "var(--grey-10)",
  // search
  "search-field-label": "var(--primary-text)",
  "search-field-text": "var(--secondary-text)",
  // homescreen
  "home-header-text": "var(--indigo-90)",
  "home-button-background": "var(--indigo-10)",
  // hotels
  "tag-background-color": "var(--indigo-10)",
  "tag-font-color": "var(--violet-70)",
  "carousel-marker-unfocused": "var(--gray-30)",
  "carousel-marker-focused": "var(--gray-100)",
  "unavailable-badge-background": "var(--gray-50)",
  "unavailable-badge-text": "var(--white)",
  "hotel-shop-content-selected": "var(--primary)",
  "hotel-shop-show-more": "var(--violet-70)",
  "checkout-edit-color": "var(--primary)",
  // hackerfares
  "hackerfare-badge-background": "var(--surface-subtle)",
  "hackerfare-badge-text": "var(--black-alpha-60)",
  // map
  "map-btn-search-area-background": "var(--white)",
  "map-btn-search-area-loading": "#E8E8E8",
  "map-marker-background": "var(--white)",
  "map-bubble-border": "var(--gray-10)",
  "map-marker-selected-background": "var(--primary)",
  "map-bubble-selected-border": "#818181",
  // Value Props
  "value-prop-color": "var(--purple-80)",
  "value-prop-border-color": "var(--purple-80)",
  "value-prop-background-color": "var(--purple-10)",
  // loading shimmer
  "loading-shimmer-background":
    "to right, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0.08) 35%, rgba(0, 0, 0, 0.04) 50%, rgba(0, 0, 0, 0.08) 65%, rgba(0, 0, 0, 0.16) 100%",
};

const nubankTheming = {
  palette: {
    type: "light" as any,
    primary: {
      light: "#820AD1",
      main: "#820AD1",
      contrastText: "#820AD1",
    },
    secondary: {
      light: "#FA6866",
      main: "#FA6866",
      contrastText: "#FA6866",
    },
    text: {
      primary: nubankVariables["black"],
      secondary: nubankColors["secondary-text"],
      disabled: nubankVariables["primary-disabled"],
      hint: "#FFF",
    },
  },
  typography: {
    fontFamily: "Graphik, sans-serif",
    htmlFontSize: 14,
    fontSize: 14,
    h1: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "2.5rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h2: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1.75rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h3: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h4: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1.375rem",
      letterSpacing: 0,
    },
    h5: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1.25rem",
      letterSpacing: 0,
    },
    h6: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    body1: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    body2: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    caption: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "0.875rem",
      letterSpacing: 0,
    },
    overline: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    subtitle1: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "0.875rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    subtitle2: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "0.875rem",
      letterSpacing: 0,
    },
    button: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.25rem",
      textTransform: "none" as const,
      letterSpacing: 0,
    },
  },
  overrides: {
    MuiLink: {
      root: {
        color: "#878787",
      },
      underlineHover: {
        "&:hover": {
          "text-decoration": "none",
        },
      },
    },
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: "black",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "black",
          },
        },
      },
      contained: {
        "&:hover": {
          backgroundColor: "black",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "black",
          },
        },
      },
    },
    MuiAlert: {
      root: {
        borderRadius: 12,
        fontWeight: 500,
      },
      standardSuccess: {
        backgroundColor: nubankColors["dark-grey"],
        color: nubankColors.white,
      },
      standardWarning: {
        backgroundColor: nubankColors["dark-grey"],
        color: nubankColors.white,
      },
      standardError: {
        backgroundColor: nubankColors["dark-grey"],
        color: nubankColors.white,
      },
      icon: {
        color: `${nubankColors.white} !important`,
      },
    },
    MuiSlider: {
      root: {
        color: nubankVariables["filter-slider-color"],
      },
    },
  },
};

export const muiTheme = asB2BMuiTheme(nubankTheming);
