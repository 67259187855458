import clsx from "clsx";
import { type TripAdvisorReviews } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Slot, TripAdvisorBadge } from "@hopper-b2b/ui";
import { Typography } from "@material-ui/core";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import styles from "./TripAdvisor.module.scss";

export type TripAdvisorProps = {
  tripAdvisorReviews?: TripAdvisorReviews;
  className?: string;
};

export const TripAdvisor = (props: TripAdvisorProps) => (
  <Slot
    id="tripadvisor"
    component={<TripAdvisorInternal {...props} />}
    {...props}
  />
);

export const TripAdvisorInternal = ({
  tripAdvisorReviews,
  className,
}: TripAdvisorProps) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  if (!tripAdvisorReviews) {
    return null;
  }

  const { overallScore, reviewsCount } = tripAdvisorReviews;

  return (
    <div className={clsx(styles.TripAdvisor, className)}>
      <TripAdvisorBadge scoreOutOfFive={overallScore * 5} withTripAdvisorIcon />
      {reviewsCount ? (
        <Typography variant="caption" className={styles.Reviews}>
          {matchesMobile
            ? // We use a template string in order to wrap reviewsCount in parentheses
              `(${reviewsCount})`
            : t("tripAdvisorReviewCount_other", {
                count: reviewsCount,
              })}
        </Typography>
      ) : null}
    </div>
  );
};
