import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { PartialParentContextWithAirDisruptionV1 } from "./types";

export const guards = {
  hasDisruptionOfferAndEnabled: (
    ctx: PartialParentContextWithAirDisruptionV1,
    _event: unknown
  ) =>
    ctx.featureFlags?.enableDisruptionProtection &&
    ctx[ParentState.disruptionV1]?.selectedOfferQuote,
};

export const GuardTypes = getObjectKeysAsObject(guards);
