import {
  COLOR_THEMES,
  defaultThemeConfigWithBasicColors,
  ThemeConfigWithBasicColors,
} from "@hopper-b2b/themes";
import { useEffect, useState } from "react";
import { useExperiment } from "@hopper-b2b/experiments";
import { FEATURE_FLAGS } from "../App";

export const colors: ThemeConfigWithBasicColors =
  defaultThemeConfigWithBasicColors;

export const COLOR_MODE_COOKIE_NAME = "app-color-mode";

// TODO: This is only temporary until we know how Nubank will tell us if they're a UV user
// We are only launching for UV users so this will default to true, until August when
// we have more info.
export const isUserTypeCookieUltravioleta = () => {
  // const cookieName = "app-user-mode";
  // const cookieRegex = `(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`;
  // const userTypeCookie = document.cookie.match(cookieRegex)?.pop();
  // // cookie will either be "uv" or nonexistent
  // return userTypeCookie === "uv";
  return true;
};

const colorModeCookie = () => {
  const cookieRegex = `(^|;)\\s*${COLOR_MODE_COOKIE_NAME}\\s*=\\s*([^;]+)`;
  // cookie will either be "dark" or "light"
  return document.cookie.match(cookieRegex)?.pop();
};

export const useDarkModePreferred = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const enableDarkModeExperiment = useExperiment(FEATURE_FLAGS.APP_DARKMODE);

  useEffect(() => {
    // is not forced light & feature is enabled & the user wanted dark
    setIsDarkMode(
      colorModeCookie() !== COLOR_THEMES.LIGHT &&
        enableDarkModeExperiment &&
        colorModeCookie() === COLOR_THEMES.DARK
    );
  }, [enableDarkModeExperiment]);
  return isDarkMode;
};
