import { ButtonWrap } from "@hopper-b2b/ui";
import styles from "./styles.module.scss";
import { UltravioletaBadge } from "../UltravioletaBadge";

import UVCheveron from "../../assets/client/uv/chevron.svg";
import UVCheveronDark from "../../assets/client/uv/chevron-dark.svg";
import clsx from "clsx";
import { useDarkModePreferred } from "../../utils/colors";

interface FintechBannerProps {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  hideBrand?: boolean;
  onClick?: () => void;
  fintechIcon: string;
  className?: string;
}

const FintechBanner = ({
  title,
  subtitle,
  onClick,
  hideBrand = false,
  fintechIcon,
  className,
}: FintechBannerProps) => {
  const isDarkMode = useDarkModePreferred();
  return (
    <ButtonWrap
      className={clsx(styles.fintechBannerContainer, className)}
      onClick={onClick}
    >
      {!hideBrand ? <UltravioletaBadge /> : null}
      <div
        className={clsx(
          "fintech-banner-row-container",
          hideBrand ? "brand-hidden" : null
        )}
      >
        <img src={fintechIcon} alt="" />
        <div className="fintech-banner-content">
          <p className="fintech-banner-content-title">{title}</p>
          {subtitle ? (
            <p className="fintech-banner-content-subtitle">{subtitle}</p>
          ) : null}
        </div>
        <div className="fintech-banner-chevron">
          {onClick ? (
            <img src={isDarkMode ? UVCheveronDark : UVCheveron} alt="" />
          ) : null}
        </div>
      </div>
    </ButtonWrap>
  );
};

export default FintechBanner;
