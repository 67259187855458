import Airplane from "../../assets/client/airplane.svg";
import AirplaneArrive from "../../assets/client/airplane-arrive.svg";
import AirplaneDepart from "../../assets/client/airplane-depart.svg";
import AirplaneFintech from "../../assets/client/airplane-fintech.svg";
import Bell from "../../assets/client/bell.svg";
import BlackLock from "../../assets/client/black-lock.svg";
import Calendar from "../../assets/client/calendar.svg";
import Card from "../../assets/client/card.svg";
import Checkmark from "../../assets/client/checkmark.svg";
import CheckmarkFilled from "../../assets/client/checkmark-filled.svg";
import CheckmarkSimple from "../../assets/client/checkmark-simple.svg";
import Chevron from "../../assets/client/chevron.svg";
import CircleCheck from "../../assets/client/circle-check.svg";
import Clock from "../../assets/client/clock.svg";
import ClockOutline from "../../assets/client/clock-outline.svg";
import Close from "../../assets/client/close.svg";
import ErrorIcon from "../../assets/client/error.svg";
import Filter from "../../assets/client/filter.svg";
import Info from "../../assets/client/info.svg";
import LocationMarker from "../../assets/client/location-marker.svg";
import Lock from "../../assets/client/lock.svg";
import MissedConnection from "../../assets/client/missed-connection.svg";
import NavArrow from "../../assets/client/nav-arrow.svg";
import OneWay from "../../assets/client/oneway.svg";
import Passenger from "../../assets/client/passenger.svg";
import RoundTrip from "../../assets/client/roundtrip.svg";
import Shield from "../../assets/client/shield.svg";
import Cancel from "../../assets/client/cancel.svg";
import Warning from "../../assets/client/warning-black.svg";
import WarningError from "../../assets/client/warning.svg";
import WarningTriangle from "../../assets/client/warning-triangle.svg";
import ShieldDisruption from "../../assets/client/shield-disruption.svg";
import MissedConnectionBlack from "../../assets/client/missed-connection-black.svg";
import AirplanDepartChfar from "../../assets/client/airplane-depart-chfar.svg";
import Reload from "../../assets/client/reload.svg";
import Refresh from "../../assets/client/refresh.svg";
import Ticket from "../../assets/client/ticket.svg";
import ArrowFork from "../../assets/client/arrow-fork.svg";
import MagnifyCheck from "../../assets/client/magnify-check.svg";
import CalendarLoading from "../../assets/client/calendar-loading.svg";
import ClockSolo from "../../assets/client/clock-solo.svg";
import DollarBill from "../../assets/client/dollar-bill.svg";
import DollarSign from "../../assets/client/dollar-sign.svg";
import BriefCase from "../../assets/client/briefcase-detail.svg";
import AirplaneOutline from "../../assets/client/airplane-outline.svg";
import A11yIcon from "../../assets/client/a11y-icon.svg";
import A11yIconActive from "../../assets/client/a11y-icon-active.svg";
import ArrowBack from "../../assets/client/arrow-back.svg";
import Redirect from "../../assets/client/redirect.svg";
import UserIcon from "../../assets/client/user.svg";
import TwoUsersIcon from "../../assets/client/two-users.svg";
import HotelIcon from "../../assets/client/hotel-icon.svg";
import GreenShieldCheck from "../../assets/client/green-shield-check.svg";
import Bed from "../../assets/client/bed.svg";
import ExternalLink from "../../assets/client/external-link.svg";
import HotelTripsIcon from "../../assets/client/hotel-trips-icon.svg";
import UnavailableIcon from "../../assets/client/unavailable-icon.svg";
import AirplaneDouble from "../../assets/client/airplane-double.svg";

// Dark Mode Icons
import A11yIconWhite from "../../assets/client/darkMode/a11y-icon-white.svg";
import A11yIconDarkActive from "../../assets/client/darkMode/a11y-icon-active.svg";
import AirplaneArriveWhite from "../../assets/client/darkMode/airplane-arrive-white.svg";
import AirplaneDepartWhite from "../../assets/client/darkMode/airplane-depart-white.svg";
import AirplaneWhite from "../../assets/client/darkMode/airplane-white.svg";
import BellWhite from "../../assets/client/darkMode/bell-white.svg";
import CalendarWhite from "../../assets/client/darkMode/calendar-white.svg";
import CardWhite from "../../assets/client/darkMode/card-white.svg";
import CheckmarkSimpleWhite from "../../assets/client/darkMode/checkmark-simple-white.svg";
import CheckmarkWhite from "../../assets/client/darkMode/checkmark-white.svg";
import ChevronDownWhite from "../../assets/client/darkMode/chevron-down-white.svg";
import CloseWhite from "../../assets/client/darkMode/close-white.svg";
import CloseWhiter from "../../assets/client/darkMode/close-white-opacity-88.svg";
import FilterWhite from "../../assets/client/darkMode/filter-white.svg";
import InfoGrey from "../../assets/client/darkMode/info-grey.svg";
import LocationMarkerWhite from "../../assets/client/darkMode/location-marker-white.svg";
import LockWhite from "../../assets/client/darkMode/lock-white.svg";
import OneWayWhite from "../../assets/client/darkMode/oneway-white.svg";
import PassengerWhite from "../../assets/client/darkMode/passenger-white.svg";
import ReloadWhite from "../../assets/client/darkMode/reload.svg";
import RoundTripWhite from "../../assets/client/darkMode/roundtrip-white.svg";
import ShieldWhite from "../../assets/client/darkMode/shield-white.svg";
import LockLargeWhite from "../../assets/client/darkMode/lock-large-white.svg";
import CancelWhite from "../../assets/client/darkMode/cancel.svg";
import WarningWhite from "../../assets/client/darkMode/warning-white.svg";
import WarningTriangleWhite from "../../assets/client/darkMode/warning-triangle.svg";
import ShieldDisruptionWhite from "../../assets/client/darkMode/shield-disruption-white.svg";
import MissedConnectionWhite from "../../assets/client/darkMode/missed-connection-white.svg";
import AirplanDepartChfarWhite from "../../assets/client/darkMode/airplane-depart-chfar-white.svg";
import RefreshWhite from "../../assets/client/darkMode/refresh-white.svg";
import TicketWhite from "../../assets/client/darkMode/ticket-white.svg";
import ArrowForkWhite from "../../assets/client/darkMode/arrow-fork-white.svg";
import AirplaneOutlineWhite from "../../assets/client/darkMode/airplane-outline-white.svg";
import UserIconWhite from "../../assets/client/darkMode/user-white.svg";
import TwoUsersIconWhite from "../../assets/client/darkMode/two-users-white.svg";
import ArrowBackWhite from "../../assets/client/darkMode/arrow-back-white.svg";
import NavArrowDark from "../../assets/client/darkMode/nav-arrow-dark.svg";
import HotelIconWhite from "../../assets/client/darkMode/hotel-icon-white.svg";
import BedWhite from "../../assets/client/darkMode/bed-white.svg";
import ExternalLinkWhite from "../../assets/client/darkMode/external-link-white.svg";
import UnavailableIconDark from "../../assets/client/darkMode/unavailable-icon.svg";
import GreenShieldCheckDark from "../../assets/client/darkMode/green-shield-check-dark.svg";
import HotelTripsIconWhite from "../../assets/client/darkMode/hotel-trips-icon-white.svg";

export const darkModeIcons = {
  a11yIcon: A11yIconWhite,
  a11yIconActive: A11yIconDarkActive,
  airplane: AirplaneWhite,
  airplaneArrive: AirplaneArriveWhite,
  airplaneDepart: AirplaneDepartWhite,
  airplaneDouble: AirplaneDouble,
  airplaneFintech: AirplaneFintech,
  airplaneOutline: AirplaneOutlineWhite,
  airplaneOutlineWhite: AirplaneOutlineWhite,
  back: ArrowBackWhite,
  bell: BellWhite,
  calendar: CalendarWhite,
  card: CardWhite,
  clock: Clock,
  error: ErrorIcon,
  cancel: CancelWhite,
  checkmark: CheckmarkWhite,
  checkmarkFilled: CheckmarkFilled,
  checkmarkSimple: CheckmarkSimpleWhite,
  chevron: ChevronDownWhite,
  close: CloseWhite,
  circleCheck: CircleCheck,
  filter: FilterWhite,
  info: InfoGrey,
  locationMarker: LocationMarkerWhite,
  locationMarkerWhite: LocationMarkerWhite,
  lock: LockLargeWhite,
  blackLock: LockWhite,
  oneway: OneWayWhite,
  reload: ReloadWhite,
  roundtrip: RoundTripWhite,
  passenger: PassengerWhite,
  missedConnection: MissedConnection,
  navArrow: NavArrowDark,
  shield: ShieldWhite,
  warning: WarningWhite,
  warningError: WarningError,
  warningTriangle: WarningTriangleWhite,
  shieldDisruption: ShieldDisruptionWhite,
  missedConnectionFintech: MissedConnectionWhite,
  airplaneDepartChfar: AirplanDepartChfarWhite,
  refresh: RefreshWhite,
  ticket: TicketWhite,
  arrowFork: ArrowForkWhite,
  magnifyCheck: MagnifyCheck,
  calendarLoading: CalendarLoading,
  clockSolo: ClockSolo,
  dollarBill: DollarBill,
  dollarSign: DollarSign,
  briefcase: BriefCase,
  flightDetailsModalOutbound: AirplaneOutlineWhite,
  flightDetailsModalInbound: LocationMarkerWhite,
  flightDetailsModalStopover: ClockOutline,
  tripDetails: AirplaneOutlineWhite,
  redirect: Redirect,
  user: UserIconWhite,
  twoUsers: TwoUsersIconWhite,
  hotelIcon: HotelIconWhite,
  greenShieldCheck: GreenShieldCheckDark,
  bed: BedWhite,
  externalLink: ExternalLinkWhite,
  hotelTrips: HotelTripsIconWhite,
  unavailable: UnavailableIconDark,
  backWhite: ArrowBackWhite,
  deleteChip: CloseWhiter,
};

export const lightModeIcons = {
  a11yIcon: A11yIcon,
  a11yIconActive: A11yIconActive,
  airplane: Airplane,
  airplaneArrive: AirplaneArrive,
  airplaneDepart: AirplaneDepart,
  airplaneDouble: AirplaneDouble,
  airplaneFintech: AirplaneFintech,
  airplaneOutline: AirplaneOutline,
  airplaneOutlineWhite: AirplaneOutlineWhite,
  back: ArrowBack,
  bell: Bell,
  calendar: Calendar,
  cancel: Cancel,
  deleteChip: Cancel,
  card: Card,
  clock: Clock,
  close: Close,
  error: ErrorIcon,
  checkmark: Checkmark,
  checkmarkFilled: CheckmarkFilled,
  checkmarkSimple: CheckmarkSimple,
  chevron: Chevron,
  circleCheck: CircleCheck,
  filter: Filter,
  info: Info,
  locationMarker: LocationMarker,
  locationMarkerWhite: LocationMarkerWhite,
  lock: Lock,
  blackLock: BlackLock,
  oneway: OneWay,
  reload: Reload,
  roundtrip: RoundTrip,
  passenger: Passenger,
  missedConnection: MissedConnection,
  navArrow: NavArrow,
  shield: Shield,
  warning: Warning,
  warningError: WarningError,
  warningTriangle: WarningTriangle,
  shieldDisruption: ShieldDisruption,
  missedConnectionFintech: MissedConnectionBlack,
  airplaneDepartChfar: AirplanDepartChfar,
  refresh: Refresh,
  ticket: Ticket,
  arrowFork: ArrowFork,
  magnifyCheck: MagnifyCheck,
  calendarLoading: CalendarLoading,
  clockSolo: ClockSolo,
  dollarBill: DollarBill,
  dollarSign: DollarSign,
  briefcase: BriefCase,
  flightDetailsModalOutbound: AirplaneOutlineWhite,
  flightDetailsModalInbound: LocationMarkerWhite,
  flightDetailsModalStopover: ClockOutline,
  tripDetails: AirplaneOutline,
  redirect: Redirect,
  user: UserIcon,
  twoUsers: TwoUsersIcon,
  hotelIcon: HotelIcon,
  greenShieldCheck: GreenShieldCheck,
  bed: Bed,
  externalLink: ExternalLink,
  hotelTrips: HotelTripsIcon,
  unavailable: UnavailableIcon,
  backWhite: ArrowBackWhite,
};
