import {
  EncodeBookingRequest,
  EncodeBookingResponseEnum,
  ivrApi,
} from "@b2bportal/ivr-api";
import { BookingType, NETWORK_CALL_FAILED } from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { trackEvent } from "../tracking/trackEvent";

async function getBookingSupportId(
  bookingId: string,
  product: BookingType,
  vipSupport?: boolean,
  funnelType?: string,
  requestType?: string
) {
  const req: EncodeBookingRequest = {
    bookingId,
    product,
    vipSupport,
    funnelType,
    requestType,
    locale: navigator.language,
  };

  try {
    const res = await ivrApi(axiosInstance).apiV0IvrEncodePost(req);

    if (res.data.EncodeBookingResponse === EncodeBookingResponseEnum.Success) {
      return res.data.encodedCode;
    }

    throw res.data;
  } catch (err) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });

    throw err;
  }
}

export default getBookingSupportId;
