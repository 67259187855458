import { useActor, useSelector } from "@xstate/react";
import { isEqual } from "lodash-es";
import { useContext } from "react";
import {
  ActorRef,
  EventObject,
  Interpreter,
  Sender,
  State,
  StateSchema,
  Typestate,
} from "xstate";
import { CheckoutStateContext } from "../context/CheckoutProvider";

type Selector<IReturnType, IContext> = (state: State<IContext>) => IReturnType;

function useCheckoutContext<
  IContext = any,
  IStateSchema extends StateSchema = any,
  IEvent extends EventObject = any,
  ITypestate extends Typestate<IContext> = any
>(): Interpreter<IContext, IStateSchema, IEvent, ITypestate> {
  const ctx = useContext(CheckoutStateContext);
  if (ctx === undefined) {
    throw new Error(`must be used within a CheckoutStateProvider`);
  }
  return ctx as Interpreter<IContext, IStateSchema, IEvent, ITypestate>;
}

export function useCheckoutStateSelector<IReturnType, IContext>(
  selector: Selector<IReturnType, IContext>
) {
  const ctx = useCheckoutContext<IContext>();
  return useSelector(ctx, selector, isEqual);
}

export function useCheckoutState<IEvent extends EventObject, IContext>(): [
  State<IContext>,
  Sender<IEvent>
] {
  const ctx = useCheckoutContext<IContext, unknown, IEvent>();
  return useActor(ctx);
}

export function useCheckoutSend<IEvent extends EventObject>(): Sender<IEvent> {
  const ctx = useCheckoutContext<unknown, unknown, IEvent>();
  return ctx.send;
}

function getChildActorRef<IContext, IEvent extends EventObject>(
  parentState: State<IContext, IEvent>,
  machineId: string
): ActorRef<IEvent> {
  const childMachineRef = parentState.context[machineId].ref;
  if (!childMachineRef) {
    throw new Error(`child machine not initialized`);
  }
  return childMachineRef;
}

export function useChildMachineState<IEvent extends EventObject, IContext>(
  machineId: string
): [State<IContext>, Sender<IEvent>] {
  const [parentState] = useCheckoutState();
  const childMachineRef = getChildActorRef(parentState, machineId);
  return useActor(childMachineRef);
}

export function useChildMachineSelector<T, IContext>(
  machineId: string,
  selector: Selector<T, IContext>,
  compare: (a: T, b: T) => boolean = isEqual
) {
  const [parentState] = useCheckoutState();
  const childMachineRef = getChildActorRef(parentState, machineId);

  return useSelector(childMachineRef, selector, compare);
}
