import { emailRegex } from "./regex-constants";
import { throttle } from "lodash-es";

export const isEmailValid = (email: string) => emailRegex.test(email);

const VALIDATE_THROTTLE_DURATION = 1000;

export const validateEmail = throttle(
  ({
    validate,
    emailRegex,
    email,
    validateEmpty = false,
    onInvalid,
    onValid,
  }) => {
    if (!validate) return;

    if (
      email &&
      (emailRegex.test(email) || (email === "" && validateEmpty === false))
    ) {
      onValid();
    } else {
      onInvalid("contactInfoEmailError");
    }
  },
  VALIDATE_THROTTLE_DURATION
);
