import { BasicColorsType } from "../variables/";

export interface ColorConfig {
  // common
  primary?: string;
  "secondary-text"?: string;
  "primary-disabled"?: string;
  "primary-hover"?: string;
  "inverse-text"?: string;
  divider?: string;
  "spinner-border-color"?: string;
  "success-color"?: string;
  "error-color"?: string;
  "pending-color"?: string;
  "warning-error-color-transparent"?: string;
  "filter-background-selected"?: string;
  "disruption-background-color"?: string;
  "background-color"?: string;
  // palette
  "palette-primary"?: string;
  "palette-secondary"?: string;
  // calendar
  "date-hover-color"?: string;
  "date-selected-color"?: string;
  "date-selected-text-color"?: string;
  "date-text-color"?: string;
  // header
  "header-primary"?: string;
  "header-travel-destination"?: string;
  "header-travel-passenger"?: string;
  "header-payment-dropdown-background"?: string;
  // search
  "search-field-label"?: string;
  "search-field-text"?: string;
  "search-autocomplete-subheader-background"?: string;
  // alerts
  "alerts-primary-alert-fill"?: string;
  "alerts-success-alert-fill"?: string;
  "alerts-primary-alert"?: string;
  "alerts-success-alert"?: string;
  // input
  "input-primary"?: string;
  "input-label-text"?: string;
  "input-background"?: string;
  "input-border-color"?: string;
  // button
  "button-background"?: string;
  "button-background-2"?: string;
  "button-background-2-focused"?: string;
  "button-background-outlined"?: string;
  "button-container-background"?: string;
  "button-text-2"?: string;
  "button-text-2-focused"?: string;
  "button-text-disabled"?: string;
  "button-background-disabled"?: string;
  "button-background-hover"?: string;
  "button-border-color"?: string;
  "button-border-color-outlined"?: string;
  // radio
  "radio-color"?: string;
  "radio-label-border-color"?: string;
  "radio-label-selected-border-color"?: string;
  "radio-background-color"?: string;
  "radio-background-color-expanded"?: string;
  // link
  "link-text"?: string;
  "action-link-active-text"?: string;
  // card
  "card-border-color"?: string;
  "card-code-color"?: string;
  "card-tag-text"?: string;
  "card-tag-text-canceled"?: string;
  "card-tag-background"?: string;
  "card-tag-background-canceled"?: string;
  // hamburger
  "hamburger-menu-color"?: string;
  // popup
  "popup-background-color"?: string;
  "loading-popup"?: string;
  // carousel
  "carousel-background-color"?: string;
  "carousel-dot-focused"?: string;
  "carousel-dot"?: string;
  "carousel-popup-header"?: string;
  // tab
  "tab-text-color"?: string;
  "tab-underline-color"?: string;
  // pagination
  "pagination-dot-background-focused"?: string;
  // banner
  "mobile-banner"?: string;
  "fare-details-banner"?: string;
  "fare-details-banner-text"?: string;
  // bucket
  "bucket-0-color"?: string;
  "bucket-1-color"?: string;
  "bucket-2-color"?: string;
  "bucket-3-color"?: string;
  // tag
  "tag-background-popular"?: string;
  "tag-text-popular"?: string;
  // price freeze
  "price-freeze-text"?: string;
  "price-freeze-card-color"?: string;
  "price-freeze-card-button"?: string;
  "price-freeze-tag"?: string;
  "price-freeze-purchase-banner-background-color"?: string;
  "price-freeze-purchase-banner-text-color"?: string;
  // price prediction
  "price-prediction-gradient-wait"?: string;
  "price-prediction-gradient-fair"?: string;
  "price-prediction-gradient-good"?: string;
  "price-prediction-gradient-great"?: string;
  // logo
  "logo-background-color"?: string;
  // rewards
  "rewards-tag-color"?: string;
  "rewards-banner-background-color"?: string;
  // apple pay
  "apple-pay-background-color"?: string;

  // section - legacy color variables WIP
  "secondary-blue"?: string;
  "tertiary-blue"?: string;
  "button-border"?: string;
  background?: string;
  "background-secondary"?: string;
  "map-bubble-border"?: string;
  "map-marker-background"?: string;
  "map-bubble-selected-border"?: string;
  "map-marker-selected-background"?: string;
  "user-icon-purple"?: string;
  "user-name-purple"?: string;
  "anchor-link-blue"?: string;
  "primary-text"?: string;
  "tertiary-text"?: string;
  "black-text"?: string;
  "inactive-grey"?: string;
  "inactive-button-icon-grey"?: string;
  "action-button-inactive-background"?: string;
  "action-button-inactive-text"?: string;
  "action-button-inactive-border"?: string;
  "action-button-active-background"?: string;
  "action-button-active-text"?: string;
  "info-details-popup-background"?: string;
  "info-details-popup-border"?: string;
  "popup-card-header-border"?: string;
  "calendar-green"?: string;
  "calendar-yellow"?: string;
  "calendar-orange"?: string;
  "calendar-red"?: string;
  "slider-bar-highlighted"?: string;
  "slider-bar-inactive"?: string;
  "badge-green-background"?: string;
  "text-green"?: string;
  "text-green-2"?: string;
  "text-green-2-transparent"?: string;
  "alert-background-success"?: string;
  "logout-link-text"?: string;
  "banner-background"?: string;
  "icon-color"?: string;
  "disabled-icon-color"?: string;
  "secondary-button-background"?: string;
  "secondary-button-background-disabled"?: string;
  "secondary-button-background-hover"?: string;
  "card-border"?: string;
  confirm?: string;
  "trips-list-background-color"?: string;
  "trips-selected-filter-color"?: string;
  "trips-link-color"?: string;
  "disabled-input-background-color"?: string;
  "flight-card-icon-color"?: string;
  "emphasis-blue"?: string;
  "grey-font-color"?: string;
  "date-range-limit-color"?: string;
  "date-disabled-color"?: string;
  "toggle-grey"?: string;
  "white-transparent-border"?: string;
  "box-shadow"?: string;
  "backdrop-shadow"?: string;
  "algomerch-airline"?: string;
  "algomerch-fareclass"?: string;
  "algomerch-price"?: string;
  "algomerch-duration"?: string;
  "algomerch-stops"?: string;
  "price-prediction-great"?: string;
  "price-prediction-good"?: string;
  "price-prediction-fair"?: string;
  "price-prediction-wait"?: string;
  "price-prediction-gradient-great-transparent"?: string;
  "tag-background-grey"?: string;
  "red-error-color"?: string;
  "red-error-color-transparent"?: string;
  "warning-error-color"?: string;
  "light-spinner-blue"?: string;
  "ftux-background-blue"?: string;
  "ftux-background-blue-transparent"?: string;
  "takeover-background-blue"?: string;
  "spinner-top-border-color"?: string;
  "wallet-offer-banner-background-color"?: string;
  "wallet-offer-banner-color"?: string;
  "checkout-banner-background-color"?: string;
  "homepage-title-primary"?: string;
  "page-content-desktop-size"?: string;
  "footer-primary"?: string;
  "footer-font-color"?: string;
  "header-desktop-height"?: string;
  "flight-search-heading-primary"?: string;
  // end of section
  // #region Customer Chat Support
  "chat-bg-agent"?: string;
  "chat-bg-customer"?: string;
  "chat-bg-customer-error"?: string;
  "chat-control-border-color"?: string;
  "chat-divider-color"?: string;
  "chat-message-border-color"?: string;
  "chat-text-agent"?: string;
  "chat-text-customer"?: string;
  "chat-text-customer-error"?: string;
  // #endregion
}

export interface StyleConfig {
  // common
  "border-radius-large"?: string;
  "border-radius"?: string;
  // button
  "button-border"?: string;
  "button-border-radius"?: string;
  // logo
  "logo-left-align-margin"?: string;

  // section - legacy style variables
  "halfsheet-border-radius"?: string;
  "z-modal"?: string;
  "z-float"?: string;
  // Responsive design variables
  "breakpoint-mobile-min"?: string;
  "breakpoint-mobile-max"?: string;
  "breakpoint-tablet-min"?: string;
  "breakpoint-tablet-max"?: string;
  "breakpoint-laptop-min"?: string;
  "breakpoint-laptop-max"?: string;
  "breakpoint-desktop-min"?: string;
  "breakpoint-desktop-max"?: string;
  "breakpoint-large-screen-min"?: string;
  "breakpoint-macbook-air"?: string;
  "font-family"?: string;
  // end of section
}

export interface ThemeConfig extends ColorConfig, StyleConfig {}

export interface ThemeConfigWithBasicColors
  extends ThemeConfig,
    BasicColorsType {}

export enum COLOR_THEMES {
  LIGHT = "light",
  DARK = "dark",
}
