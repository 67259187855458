import {
  CallState,
  WalletOffer,
  WalletVoucherStatusEnum,
} from "@hopper-b2b/types";
import { createSelector } from "@reduxjs/toolkit";
import { LodgingAppState } from "../../../../reducer";
import dayjs from "dayjs";

export const getWalletOffers = (
  state: LodgingAppState
): WalletOffer[] | undefined => {
  return state.wallet.walletOffers;
};

export const getWalletBestOffers = (
  state: LodgingAppState
): WalletOffer | undefined => state.wallet.bestOffer;

export const getWalletCreditOffer = (state: LodgingAppState): WalletOffer =>
  state.wallet.creditOffer;

export const getWalletOffersCallState = (state: LodgingAppState): CallState =>
  state.wallet.walletOfferCallState;

export const getSelectedOffer = (
  state: LodgingAppState
): WalletOffer | undefined => state.wallet.selectedWalletOffers;

export const hasSelectedCreditOffer = (state: LodgingAppState): boolean =>
  !!state.wallet.selectedCreditOffer;

export const getSelectedCreditOffer = (state: LodgingAppState): WalletOffer =>
  state.wallet.selectedCreditOffer;

export const isWalletOffersLoading = createSelector(
  getWalletOffersCallState,
  (walletOfferCallState: CallState) =>
    walletOfferCallState === CallState.InProcess
);

export const getHasWalletOffers = createSelector(
  getWalletOffers,
  getWalletCreditOffer,
  (offers?: WalletOffer[], creditOffer?: WalletOffer) =>
    !!offers?.length || !!creditOffer
);

export const getTotalWalletSavings = createSelector(
  getSelectedOffer,
  getSelectedCreditOffer,
  (voucher, creditOffer) => {
    return {
      ...voucher?.amount,
      ...creditOffer?.amount,
      value: (voucher?.amount.value || 0) + (creditOffer?.amount.value || 0),
    };
  }
);

export const getIsOfferPercentOff = createSelector(
  getSelectedOffer,
  (offer: WalletOffer) => {
    return offer.percentage !== null;
  }
);

export const getWalletOfferStatus = (
  state: LodgingAppState
): WalletVoucherStatusEnum => state.wallet.offerStatus;

export const getWalletTrackingProperties = createSelector(
  getSelectedOffer,
  getWalletOfferStatus,
  (offer, offerStatus) => {
    const baseProperties = {
      offer_id: offer?.id,
      offer_name: offer?.offerContent?.title,
      // program_id: offer.offerContent.title,
      offer_applicable: true,
      // offer_discount if percentage, is based off the cheapest hotel
      // these two won't be available until checkout
      // offer_discount: 50,
      // offer_discount_usd: 50,
      offer_expiration_days: dayjs(offer?.expiresOn).diff(dayjs(), "days"),
      ...(offer?.percentage
        ? {
            offer_discount_percentage_value: offer.percentage * 100,
            offer_discount_type: "percentage",
          }
        : { offer_discount_type: "cash" }),
      ...(offerStatus ? { offer_status: offerStatus } : null),
      ...offer?.trackingPropertiesV2,
    };

    return baseProperties;
  }
);
