import {
  Ref,
  ChangeEvent,
  FocusEvent,
  ElementType,
  ComponentPropsWithoutRef,
  ElementRef,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
  useRef,
  useId,
} from "react";

import { IB2BTextFieldProps } from "@hopper-b2b/ui";
import { NotInputTextualAttributes } from "./CustomInputTypes";
import clsx from "clsx";
import "./CustomInput.scss";

interface CustomInputProps extends IB2BTextFieldProps {
  defaultValue?: unknown;
  value?: unknown;
  ref?: Ref<HTMLInputElement | HTMLDivElement>;
}

const CustomInput = ({
  onChange,
  onFocus,
  onBlur,
  errorHelper,
  inputMode,
  width,
  error,
  className,
  id,
  allowInputSizeOverride,
  defaultValue,
  type,
  value,
  InputProps,
  ref,
  label,
  required,
  ...rest
}: CustomInputProps) => {
  const inputId = useInputId(id);

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleBlur = (
    event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (onBlur) {
      onBlur(event.target.value);
    }
  };

  const handleFocus = (
    event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (onFocus) {
      onFocus(event.target.value, event);
    }
  };

  return (
    <TextFieldRoot
      id={inputId}
      className={clsx(
        "custom-textfield",
        { error: error || !!errorHelper, required },
        className
      )}
      disableUnderline={true}
      {...InputProps}
      defaultValue={defaultValue}
      value={value}
      ref={ref as Ref<HTMLInputElement>}
      onChange={(ev) => handleChange(ev)}
      onFocus={(ev) => handleFocus(ev)}
      onBlur={(ev) => handleBlur(ev)}
      helperText={errorHelper}
      label={label}
      required={required}
      style={{
        width: width,
      }}
      {...rest}
    />
  );
};

type ComponentPropsWithout<
  T extends ElementType,
  O extends
    | Omit<string, keyof ComponentPropsWithoutRef<T>>
    | keyof ComponentPropsWithoutRef<T>
> = Omit<ComponentPropsWithoutRef<T>, O & string>;

// References Radix UI's Root Textfield
// https://github.com/radix-ui/themes/blob/main/packages/radix-ui-themes/src/components/text-field.tsx
type TextFieldRootElement = ElementRef<"input">;
type TextFieldRootOwnProps = {
  defaultValue?: unknown;
  value?: unknown;
  type?: InputHTMLAttributes<unknown>["type"];
  disableUnderline?: boolean;
  inputProps?: Record<string, unknown>;
};
type TextFieldInputProps = ComponentPropsWithout<
  "input",
  | NotInputTextualAttributes
  | "color"
  | "defaultValue"
  | "size"
  | "type"
  | "value"
>;
interface TextFieldRootProps
  extends TextFieldInputProps,
    TextFieldRootOwnProps {
  helperText?: ReactNode;
  label?: ReactNode;
}

const TextFieldRoot = forwardRef<TextFieldRootElement, TextFieldRootProps>(
  (props, forwardedRef) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const {
      id,
      className,
      style,
      helperText,
      label,
      required,
      value,
      defaultValue,
      disableUnderline,
      inputProps,
      ...rest
    } = props;

    return (
      <div
        style={style}
        className={clsx("TextFieldRoot", className)}
        onPointerDown={(event) => {
          const target = event.target as HTMLElement;
          if (target.closest("input, button, a")) return;

          const input = inputRef.current;
          if (!input) return;

          const cursorPosition = 0;

          requestAnimationFrame(() => {
            // Only some input types support this, browsers will throw an error if not supported
            // See: https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/setSelectionRange#:~:text=Note%20that%20according,not%20support%20selection%22.
            try {
              input.setSelectionRange(cursorPosition, cursorPosition);
            } catch (e) {
              // Do nothing
            }
            input.focus();
          });
        }}
      >
        {label != null && label !== "" && (
          <label htmlFor={id} id={`${id}-input-label`}>
            {`${label}${required ? "*" : ""}`}
          </label>
        )}
        <input
          spellCheck="false"
          className={clsx("TextFieldInput")}
          {...inputProps}
          {...rest}
          id={id}
          value={value as string | number}
          defaultValue={defaultValue as string | number}
          ref={forwardedRef || inputRef}
        />
        {helperText && <p id={`${id}-helper-text`}>{helperText}</p>}
      </div>
    );
  }
);
TextFieldRoot.displayName = "TextField.Root";

/**
Custom hook that generates an id in order to always render TextField with an id.
This will prevent an a11y issue "Ensures every form element has a label".
If you need a specific id then you can enforce it by passing `hardcodedId`.
Otherwise, it always generates a unique id using the `useId` hook.
@param hardcodedId - Optional hardcoded id that will come from the `id` prop on `B2BTextField`.
@returns The input id, either the hardcoded id or an id generated by `useId`.
*/
function useInputId(hardcodedId?: string) {
  const generatedId = useId();

  return hardcodedId ? hardcodedId : generatedId;
}

export { CustomInput };
